<template>
  <div class="merchants">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfig"
      :pageTitle="$t('merchant.merchant-overview')"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @viewBtnClick="handleViewData"
      @newBtnClick="handleNewData"
      @pageInfoChange="pageInfoChangeAction"
    >
      <template #otherHandler="scope">
        <!-- 由于这里的编辑、删除按钮的控制条件和公共组件的不一样，为了公共组件的简洁性这里按钮重写 -->
        <el-button
          class="opt-btn"
          v-if="isUpdate && !isSelf(scope.row.userName)"
          icon="el-icon-edit"
          size="mini"
          type="primary"
          plain
          @click="handleEditData(scope.row.id)"
          >{{ $t('general.edit') }}</el-button
        >
        <el-button
          v-if="
            isSwitch &&
            isNormalStatus(scope.row.status) &&
            !isSelf(scope.row.userName)
          "
          class="opt-btn"
          icon="el-icon-lock"
          size="mini"
          plain
          type="warning"
          @click="handleEnableClick(scope.row, 'disable')"
          >{{ $t('general.disable') }}</el-button
        >
        <el-button
          v-if="
            isSwitch &&
            !isNormalStatus(scope.row.status) &&
            !isSelf(scope.row.userName)
          "
          class="opt-btn"
          icon="el-icon-unlock"
          size="mini"
          plain
          @click="handleEnableClick(scope.row, 'enable')"
          >{{ $t('general.enable') }}</el-button
        >
        <el-button
          class="opt-btn"
          v-if="isDelete && !isSelf(scope.row.userName)"
          icon="el-icon-delete"
          size="mini"
          type="danger"
          plain
          @click="handleDeleteCurrentItem(scope.row)"
          >{{ $t('general.delete') }}</el-button
        >
      </template></page-content
    >
  </div>
</template>

<script setup>
import { ref, computed, getCurrentInstance } from 'vue'

import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'
import { searchFormConfig } from './config/merchant.form'
import { contentTableConfig } from './config/merchant.table'

import { useRouter } from 'vue-router'
import { cloneDeep } from 'lodash'
import { usePermission } from '@/hooks/use-permission'
import { getItem } from '@/utils/storage'

const tableOptions = {
  moduleName: 'tenant', // 所属的模块
  pageName: 'merchants', // 所属的页面
  contentTableConfig, // 表格列的基础配置
  // isShowCreateSlot: false, // 是否显示添加按钮的插槽
  isShowEditBtn: false,
  isShowDeleteBtn: false,
  pageAction: 'tenant/getMerchantListByPage', // 获取表格的Action
  deleteRecordAction: 'tenant/handleDeleteMerchant', // 删除record的Action
  pageCountGetter: 'tenant/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'tenant/pageListData' // 获取表格列表数据getters
}
const tableOptionsConfig = computed(() => tableOptions)
const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

const pageContentRef = ref('')
const pageSearchRef = ref('')

const formateData = (data) => {
  const formateObject = cloneDeep(data)
  formateObject.tenantId = data.tenantId ? data.tenantId.join(',') : ''
  return formateObject
}

const isUpdate = usePermission(
  tableOptions.moduleName,
  tableOptions.pageName,
  'edit'
)
const isDelete = usePermission(
  tableOptions.moduleName,
  tableOptions.pageName,
  'delete'
)

const isSwitch = usePermission(
  tableOptions.moduleName,
  tableOptions.pageName,
  'switch'
)

const isNormalStatus = (status) => {
  return status === '00'
}

const userInfo = getItem('userInfo') || {}

const isSelf = computed(() => {
  return (name) => {
    return userInfo.userName === name
  }
})

const handleQueryClick = (queryInfo) => {
  // 调用page-search中的getPageData方法
  pageContentRef.value.getPageData(formateData(queryInfo))
}
const handleResetClick = () => {
  pageContentRef.value.getPageData()
}
const router = useRouter()
const handleViewData = (id) => {
  router.push(`/merchant/view/${id}`)
}

const handleEditData = (id) => {
  router.push(`/merchant/edit/${id}`)
}

const handleNewData = () => {
  router.push(`/merchant/new`)
}

const handleEnableClick = async (row, type) => {
  const url =
    type === 'enable' ? 'handleEnableMerchant' : 'handleDisableMerchant'
  handleMessageCommit(`tenant/${url}`, row.id).then(() => {
    pageContentRef.value.getPageData()
  })
}

const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData(formateData(formData))
}
</script>
